import { Component, inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

const enterTransition = transition(':enter', [
  style({
    transform: 'translateX(100%)',
  }),
  animate(
    '500ms ease-in-out',
    style({
      transform: 'translateX(0px)',
    })
  ),
]);

const slideOverAnimation = trigger('slideOverAnimation', [
  state(
    'open',
    style({
      transform: 'translateX(0)',
    })
  ),
  state(
    'closed',
    style({
      transform: 'translateX(100%)',
    })
  ),
  transition('open <=> closed', [animate('500ms ease-in-out')]),
]);

const toggleModal = trigger('toggleModal', [enterTransition]);

@Component({
  selector: 'app-slide-in-right-modal',
  standalone: true,
  imports: [CommonModule, A11yModule, RouterLink, RouterOutlet],
  templateUrl: './slide-in-right-modal.component.html',
  styles: ``,
  animations: [toggleModal, slideOverAnimation],
})
export class SlideInRightModalComponent {
  isModalOpen: boolean = true;

  router = inject(Router);

  //flag to indicate if the modal should slide open on page load
  @Input() slideOpen: boolean = false;
  @Input() showBackLink = true;
  @Input() nextPath!: string;
  @Input() returnPath!: string;
  @Output() action = new EventEmitter<void>();

  // we check which page to return to after closing the modal
  modalReturnPage!: string;


  closeModal() {
    this.isModalOpen = false;
    this.action.emit()
  }

  // async goToUrlAfterClosingModal(event: any) {
  //   if (event.fromState === 'open' && event.toState === 'closed') {
  //     // we check if the modal is closed now
  //     (await event.fromState) === 'open' && event.toState === 'closed';

  //     // this.router.navigate([this.modalReturnPage]);
  //     if (this.returnPath) {
  //       this.router.navigate([this.returnPath]);
  //     } else {
  //       this.goBack();
  //     }
  //   }
  // }

  // goBack() {
  //   history.back();
  // }
}
