<div
  class="relative z-[80]"
  aria-labelledby="slide-over-title"
  role="dialog"
  data-open="true"
  aria-modal="true"
  tabindex="-1"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <!-- Background backdrop, show/hide based on slide-over state. -->
  <div
    class="fixed inset-0 opacity-20"
    [ngClass]="{ 'bg-slate-600': isModalOpen }"
  ></div>

  <div class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <div
        class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16"
      >
 

        <div
          class="pointer-events-auto w-screen max-w-lg transform transition duration-500 ease-in-out sm:duration-700"
          [@slideOverAnimation]="isModalOpen ? 'open' : 'closed'"

        >
          <!-- the @.disabled directive will disable all animations under it -->
          <div
            [@.disabled]="!slideOpen"
            @toggleModal
            class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
          >
            <div class="px-6 pb-5 pt-[1.94rem]">
              <div class="grid grid-cols-2">
                <div class="flex flex-col gap-[0.44rem]">
                  @if (showBackLink) {
                    <a
                      (click)="closeModal()"
                      class="back__wrapper inline-flex w-fit cursor-pointer focus-visible:ring-mango"
                      tabindex="0"
                    >
                      <svg
                        class="translate-x-[-4px]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M12.5 4.66699L7.5 10.5003L12.5 16.3337"
                          stroke="#171717"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span>Back</span>
                    </a>
                  }
                </div>
                

                <div class="ml-3 flex h-7 items-center justify-self-end">
                  <button
                    (click)="closeModal()"
                    type="button"
                    class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus-visible:outline-mango"
                  >
                    <span class="absolute -inset-2.5"></span>
                    <span class="sr-only">Close panel</span>

                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      aria-hidden="true"
                      stroke="currentColor"
                    >
                      <path
                        d="M18 6L6 18M6 6L18 18"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex-1 divide-gray-200 overflow-y-auto px-6 pb-8 pt-5">
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
